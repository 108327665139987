import { reactLocalStorage } from 'reactjs-localstorage';
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  FETCH_USER_HISTORY,
  FETCH_USER_HISTORY_SUCCESS,
  FETCH_USER_HISTORY_ERROR,
  LOGOUT,
  UPDATE_USER_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  OTP_ERROR,
  OTP_SUCCESS,
  OTP_CLEAR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS_ERROR,
  LOGIN_VIA_OTP_SUCCESS,
  LOGIN_VIA_OTP_ERROR,
  START_OTP_TIMER,
  STOP_OTP_TIMER,
  RESET_OTP_TIMER,
  CONTACTUSMAIL,
  SHOW_LOGIN_POPUP,
  LOGIN_VIA_OTP_SUCCESS_LOGIN_FIELD,
  FETCH_UNPAID_USER_HISTORY_SUCCESS,
} from './authTypes';

let INITIAL_MINS = 9;
let INITIAL_SECONDS = 59;

let guestUser = null;
try {
  if (localStorage.getItem('guest_user')) {
    guestUser = JSON.parse(localStorage.getItem('guest_user'));
    if (
      Object.entries(guestUser).length === 0 ||
      !guestUser?.guest_user_id ||
      !guestUser?.guest_email ||
      !guestUser?.guest_id
    ) {
      reactLocalStorage.remove('userHistory');
      reactLocalStorage.remove('userUnpaidHistory');
      localStorage.removeItem('guest_user');
      guestUser = null;
    }
  }
} catch (e) {
  console.log('Error parsing localstorage');
}

let initialState = {
  loggedInUser: JSON.parse(localStorage.getItem('user') || null) || null,
  guestUser: guestUser || null,
  loginError: null,
  registerError: null,
  userLoyaltyHistory: { loyalty: [], fnbLoyalty: [] },
  userEwalletHistory: { walletLogs: [], walletBalance: [] },
  userHistory: reactLocalStorage.getObject('userHistory', [], true),
  userUnpaidHistory: reactLocalStorage.getObject('userUnpaidHistory', [], true),
  userHistorySuccess: false,
  userHistoryLoading: false,
  userHistoryError: null,
  forgotPasswordUserDetails: null,
  forgotPasswordError: null,
  otpSuccess: null,
  otpError: null,
  resetPasswordSuccess: null,
  resetPasswordError: null,
  loginOtpSuccess: null,
  loginOtpError: null,
  timer: {
    minutes: INITIAL_MINS,
    seconds: INITIAL_SECONDS,
  },
  timerCompleted: false,
  mail_data: null,
  showLoginPopup: false,
  loginField: null,
  user_token: reactLocalStorage.get('user_token', null, true),
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_USER_TOKEN':
      reactLocalStorage.set('user_token', payload);
      return {
        ...state,
        user_token: payload,
      };
    case 'CLEAR_USER_TOKEN':
      reactLocalStorage.remove('user_token');
      return {
        ...state,
        user_token: null,
      };
    case LOGIN_VIA_OTP_SUCCESS:
      return {
        ...state,
        loginOtpSuccess: payload,
        loginOtpError: null,
      };
    case LOGIN_VIA_OTP_ERROR:
      return { ...state, loginOtpError: payload };
    case LOGIN_VIA_OTP_SUCCESS_LOGIN_FIELD:
      return { ...state, loginField: payload };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: payload,
        resetPasswordError: null,
      };
    case RESET_PASSWORD_SUCCESS_ERROR:
      return { ...state, resetPasswordError: payload };
    case OTP_SUCCESS:
      return { ...state, otpSuccess: payload, otpError: null };
    case OTP_CLEAR:
      return { ...state, otpError: null };
    case OTP_ERROR:
      return { ...state, otpError: payload };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordUserDetails: payload,
        forgotPasswordError: null,
      };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, forgotPasswordError: payload };
    case LOGIN:
    case REGISTER:
      reactLocalStorage.remove('userHistory');
      reactLocalStorage.remove('userUnpaidHistory');
      localStorage.removeItem('guest_user');
      return {
        ...state,
        loggedInUser: null,
        loginError: null,
        registerError: null,
        guestUser: null,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      localStorage.removeItem('guest_user');
      return {
        ...state,
        loggedInUser: payload,
        guestUser: null,
      };
    case 'GUEST_REGISTER_SUCCESS':
      return {
        ...state,
        guestUser: payload,
      };
    case LOGOUT:
      reactLocalStorage.remove('userHistory');
      reactLocalStorage.remove('userUnpaidHistory');
      localStorage.removeItem('guest_user');
      reactLocalStorage.remove('user_token');
      return {
        ...state,
        loggedInUser: null,
        guestUser: null,
        userHistory: [],
        userUnpaidHistory: [],
        user_token: null,
      };
    case LOGIN_ERROR:
      reactLocalStorage.remove('userHistory');
      reactLocalStorage.remove('userUnpaidHistory');
      localStorage.removeItem('guest_user');
      return { ...state, loggedInUser: null, loginError: payload, guestUser: null };
    case REGISTER_ERROR:
      reactLocalStorage.remove('userHistory');
      reactLocalStorage.remove('userUnpaidHistory');
      localStorage.removeItem('guest_user');
      return {
        ...state,
        loggedInUser: null,
        registerError: payload,
        guestUser: null,
      };
    case FETCH_USER_HISTORY:
      return {
        ...state,
        // userHistory: [],
        // userUnpaidHistory: [],
        userLoyaltyHistory: { loyalty: [], fnbLoyalty: [] },
        userEwalletHistory: { walletLogs: [], walletBalance: [] },
        userHistorySuccess: true,
        userHistoryLoading: true,
      };
    case FETCH_USER_HISTORY_SUCCESS:
      return {
        ...state,
        userHistory: payload,
        userHistorySuccess: false,
        userHistoryLoading: false,
      };
    case FETCH_UNPAID_USER_HISTORY_SUCCESS:
      return {
        ...state,
        userUnpaidHistory: payload,
      };
    case FETCH_USER_HISTORY_ERROR:
      return {
        ...state,
        userHistory: [],
        userUnpaidHistory: [],
        userLoyaltyHistory: { loyalty: [], fnbLoyalty: [] },
        userEwalletHistory: { walletLogs: [], walletBalance: [] },
        userHistoryError: payload,
        userHistoryLoading: false,
      };
    case 'FETCH_LOYALTY_HISTORY_SUCCESS':
      return {
        ...state,
        userLoyaltyHistory: payload,
      };
    case 'FETCH_EWALLET_HISTORY_SUCCESS':
      return {
        ...state,
        userEwalletHistory: payload,
      };
    case START_OTP_TIMER: {
      let oldTimer = state.timer;
      let newTimer = {};
      let newSeconds = oldTimer.seconds;
      let newMinutes = oldTimer.minutes;
      let timerCompleted = false;

      if (newSeconds === 0) {
        newSeconds = 59;
        if (newMinutes === 0) {
          // timer has ended
          timerCompleted = true;
          newSeconds = 0;
          newMinutes = 0;
          if (action.payload.timerInterval) {
            clearInterval(action.payload.timerInterval);
          }
        } else {
          newMinutes -= 1;
        }
      } else {
        newSeconds -= 1;
      }

      newTimer = {
        seconds: newSeconds,
        minutes: newMinutes,
      };

      return {
        ...state,
        timer: newTimer,
        timerCompleted,
      };
    }
    case STOP_OTP_TIMER:
      // if (action.payload && action.payload.timerInterval) {
      //   clearInterval(action.payload.timerInterval);
      // }
      return { ...state };
    // return{...state,
    // }
    case RESET_OTP_TIMER: {
      return {
        ...state,
        timer: {
          seconds: INITIAL_SECONDS,
          minutes: INITIAL_MINS,
        },
        timerCompleted: false,
      };
    }
    case CONTACTUSMAIL:
      return { ...state, mail_data: action.payload };
    case SHOW_LOGIN_POPUP: {
      return {
        ...state,
        showLoginPopup: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
