import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import { globalConfigContext } from '@context/GlobalConfigContext';

export default function CountryCodeDropdown({
  className,
  onSelectCountry,
  isPrivate = false,
  ...props
}) {
  const globalOptions = useSelector((state) => state.movies.globalOptions);
  const { countries } = useContext(globalConfigContext);
  const [countriesCopy, setCountriesCopy] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [countryCodeSearch, setCountryCodeSearch] = useState('');
  const { pathname } = useLocation();

  const { loggedInUser, guestUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      countries &&
      countries.length > 0 &&
      (!selectedCountryCode || !selectedCountryId)
    ) {
      let country = countries[0];
      let operationCountry = globalOptions.find(
        (g) => g.go_key === 'OPERATION_COUNTRY',
      );
      if (operationCountry) {
        let country_id = parseInt(operationCountry.go_value);
        let cx = countries.find((c) => c.country_id == country_id);
        if (cx) {
          country = cx;
        }
      }
      setSelectedCountryCode(country.country_mob_code);
      setSelectedCountryId(country.country_id);

      if (onSelectCountry) {
        setImmediate(() => {
          onSelectCountry({
            country_id: country.country_id,
            country_mob_code: country.country_mob_code,
          });
        });
      }
    }

    if (countries) {
      setCountriesCopy([...countries]);
    }
  }, [countries, globalOptions]);

  useEffect(() => {
    if (countryCodeSearch && countryCodeSearch.length > 0) {
      let newCountries = countries.filter(
        (c) =>
          ('+' + c?.country_mob_code)?.includes(countryCodeSearch) ||
          c?.country_name?.toLowerCase()?.includes(countryCodeSearch.toLowerCase()),
      );
      setCountriesCopy(newCountries);
    } else {
      setCountriesCopy(countries ? [...countries] : []);
    }
  }, [countryCodeSearch]);

  const handleCountrySelect = ({ country_mob_code, country_id }) => {
    setSelectedCountryCode(country_mob_code);
    setSelectedCountryId(country_id);
    if (onSelectCountry) {
      onSelectCountry({ country_id, country_mob_code });
    }
  };

  useEffect(() => {
    if (
      loggedInUser &&
      loggedInUser.country_mob_code &&
      countries &&
      countries.length > 0
    ) {
      let usersCountry = countries.find(
        (x) => x.country_mob_code == loggedInUser.country_mob_code,
      );
      if (usersCountry) {
        handleCountrySelect(usersCountry);
      }
    }
    if (
      guestUser &&
      guestUser.guest_country_code &&
      countries &&
      countries.length > 0
    ) {
      let usersCountry = countries.find(
        (x) => x.country_mob_code == guestUser.guest_country_code,
      );
      if (usersCountry) {
        setTimeout(() => {
          handleCountrySelect(usersCountry);
        }, 300);
      }
    }
  }, [loggedInUser, countries, guestUser]);

  return (
    <div className={`code ${className}`}>
      <Dropdown className="">
        <Dropdown.Toggle className="dropdownClassic p-0" id="dropdown-basic">
          <span className="code" id="code_country_input">
            +{selectedCountryCode}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu align="right" className="dropdownItems country_code">
          <Dropdown.Item className={`selected ${isPrivate && ' dropdown-golden '}`}>
            <input
              className={`h-auto py-2 ${
                className.includes('form-control') && 'form-control'
              } ${pathname === '/user-profile' && 'mb-0'}`}
              type="text"
              value={countryCodeSearch}
              onChange={(e) => setCountryCodeSearch(e.target.value)}
              placeholder="Search"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                return;
              }}
            />
          </Dropdown.Item>
          {countriesCopy?.map((country, i) => (
            <Dropdown.Item
              key={`${country.country_name}-${i}`}
              className={`selected ${isPrivate && ' dropdown-golden '}`}
              id="country_code_dropdown"
              onClick={() => handleCountrySelect(country)}
            >
              +{country.country_mob_code} ({country.country_name})
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
