import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR,
  FETCH_FNB_ITEMS,
  FETCH_FNB_ITEMS_ERROR,
  SELECT_FNB_ITEM,
  FNB_RESERVATION_DONE,
  SET_RECIPE_ITEMS,
  SELECT_MODIFIER_UNDER_RECIPES,
  CLOSE_RECIPE_MODIFIER_POPUP,
  SET_TAX_PERCENT_FNB,
  SET_FT_ID,
  SET_FNB_LOADER,
  CLEAR_FNB_STATE,
  FNB_BOOKING_ERROR,
  UPDATE_FNB_DATA_FROM_BACKEND,
} from './foodAndBeveragesTypes';

import FnbService from '@apiService/FnbService.js';
import { toast } from 'react-toastify';
import moment from 'moment';

let stripePaymentMode = {
  pm_id: 14,
  pm_payment_mode: 'Stripe',
};

const fetchCategoriesSuccess = (categories) => {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: categories,
  };
};

const fetchCategoriesError = (err) => {
  return {
    type: FETCH_CATEGORIES_ERROR,
    payload: err,
  };
};

const fetchFnbItemsSuccess = (fnbItems) => {
  return {
    type: FETCH_FNB_ITEMS,
    payload: fnbItems,
  };
};

const fetchFnbItemsError = (err) => {
  return {
    type: FETCH_FNB_ITEMS_ERROR,
    payload: err,
  };
};

export const setFnbReservationDone = () => ({
  type: FNB_RESERVATION_DONE,
});

export const setRecipeItems = (payload) => ({
  type: SET_RECIPE_ITEMS,
  payload,
});

export const selectModifierUnderRecipes = (payload) => ({
  type: SELECT_MODIFIER_UNDER_RECIPES,
  payload,
});

export const setTaxPercentInFnb = (payload) => ({
  type: SET_TAX_PERCENT_FNB,
  payload,
});

export const onCloseRecipeModifierPopup = () => ({
  type: CLOSE_RECIPE_MODIFIER_POPUP,
});

export const clearFnbState = () => ({
  type: CLEAR_FNB_STATE,
});

export const onSelectFnb = (type, item) => {
  return {
    type: SELECT_FNB_ITEM,
    payload: { type, item },
  };
};

const setFnbLoader = (payload) => ({
  type: SET_FNB_LOADER,
  payload,
});

export const fetchFnbCategories = () => async (dispatch) => {
  try {
    let { data } = await FnbService.GetAllFnbCategories({
      categories_with_items: 'Y',
    });

    if (data.status) {
      dispatch(fetchCategoriesSuccess(data.Records));
    } else {
      dispatch(fetchCategoriesError(data.message));
    }
  } catch (err) {
    console.log('error in fetching categories');
  }
};

export const fetchFnbItems = (payload, setShowLoader) => async (dispatch) => {
  setShowLoader && setShowLoader(true);
  dispatch(setFnbLoader(true));
  try {
    const { cinema_id, reservation_id } = payload;

    const fnbPayload = {
      category: null,
      aggregator_cinema_id: cinema_id,
      cinema_id,
      isCombo: false,
      isEgg: '',
      isVeg: '',
      search: '',
      subCategory: null,
      reservation_id,
    };
    const { data } = await FnbService.GetAllFnbItems(fnbPayload);
    if (
      data.status &&
      data.Records &&
      data.Records.Records &&
      data.Records.Records.length > 0
    ) {
      dispatch(
        fetchFnbItemsSuccess(
          data.Records.Records.filter((f) => f?.isAvailableToday),
        ),
      );
    } else {
      // dispatch(RedirectToCheckOut());
      dispatch(fetchFnbItemsSuccess([]));
    }
  } catch (err) {
    dispatch(fetchFnbItemsError(err.message));
  }
  setShowLoader && setShowLoader(false);
};

export const skipFnbItems = (payload) => async (dispatch) => {
  try {
    const { cinema_id, reservation_id, city_id } = payload;
    const fnbPayload = {
      category: null,
      aggregator_cinema_id: cinema_id,
      cinema_id,
      isCombo: false,
      isEgg: '',
      isVeg: '',
      search: '',
      subCategory: null,
      reservation_id,
      city_id,
    };

    const { data: fnbData } = await FnbService.GetAllFnbItems(fnbPayload);
    let skipFnb = false;
    skipFnb = fnbData.status == false || fnbData?.Records?.Records?.length === 0;

    return skipFnb;
  } catch (err) {
    return true;
  }
};

export const saveFnbItems = (category_id) => async (dispatch, getState) => {
  try {
    let state = getState();
    let reservation_id = state.seatLayout.reservation_id;
    let selected_fnb_items = state.fnb.selected_fnb_items;
    let fnb_price = state.fnb.fnb_price;

    if (selected_fnb_items.length > 0) {
      selected_fnb_items.forEach((fnb) => {
        if (fnb?.Quantity) {
          fnb.quantity = fnb.Quantity;
        }
      });
    }

    const { data } = await FnbService.SaveFnbItems({
      reservation_id,
      selected_fnb_items,
      fnb_price,
      category_id,
    });

    if (data?.status) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    console.log('error while saving fnb items: ', err);
    toast.error(err.message);
    return false;
  }
};

export const updateFnbDataIfNotPresent =
  (fnb_data) => async (dispatch, getState) => {
    // UPDATE_FNB_DATA_FROM_BACKEND
    if (
      !fnb_data ||
      !(fnb_data?.selected_fnb_items?.length > 0) ||
      !fnb_data?.fnb_price
    ) {
      return;
    }

    try {
      let state = getState();
      let existing_selected_fnb_items = state.fnb.selected_fnb_items;
      let existing_fnb_price = state.fnb.fnb_price;

      if (
        existing_selected_fnb_items?.length === 0 &&
        existing_fnb_price?.total_price === 0
      ) {
        dispatch({
          type: UPDATE_FNB_DATA_FROM_BACKEND,
          payload: {
            ...fnb_data,
          },
        });
      } else {
        return;
      }
    } catch (err) {
      console.log('error when resaving fnb data', err);
    }
  };

export const bookFnb =
  ({
    reservation_id,
    selectedFnbItems,
    cinemaObj,
    currency_id,
    ft_total_amount,
    ft_net_amount,
    email,
    mobile,
    countryMobCode,
    ft_currency_name,
  }) =>
  async (dispatch, getState) => {
    try {
      let state = getState();

      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;

      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      // let email = loggedInUser?.cust_email
      // let guest_email = guestUser?.guest_email

      let optionalPayload = {};

      if (!loggedInUser && guestUser) {
        optionalPayload.is_guest_user = 'Y';
        optionalPayload.guest_details = {
          guest_email: guestUser.guest_email,
          guest_user_id: guestUser.guest_user_id,
          guest_mobile: guestUser.guest_mobile,
          guest_country_code: guestUser.guest_country_code,
        };
      }

      let scheduleDetails = state.movies.scheduleDetails;

      let showtime = `${moment(scheduleDetails?.ss_actual_start_date_time).format(
        'YYYY-MM-DD',
      )} ${moment(scheduleDetails?.ss_start_show_time, 'HH:mm:ss').format(
        'HH:mm',
      )}`;

      let fnbItemList = window.structuredClone
        ? window.structuredClone(selectedFnbItems)
        : selectedFnbItems;
      fnbItemList.map((item) => (item.quantity = item.Quantity));

      const { data } = await FnbService.BookFnb({
        ...optionalPayload,
        isGroupRateCard: true,
        reservation_id,
        fnbItemList,
        payment_mode_id: stripePaymentMode.pm_id,
        cinema_id: cinemaObj.cinema_id,
        currency_id,
        ft_total_amount,
        ft_net_amount,
        ft_order_status: 3,
        aggregator_cinema: cinemaObj,
        offer: { t_offer_price: 0 },
        payment_mode: stripePaymentMode,
        booking_source_id: 2,
        fst_booking_source: 'Online',
        fnb_delivery_time: showtime,
        customer_details: {
          email,
          country_code: countryMobCode,
          mobile,
        },
        ft_currency_name,
        send_email: false,
      });
      if (data && data.status && data.Records) {
        console.log('data :>> ', data);
        dispatch({ type: SET_FT_ID, payload: data.Records.ft_id });
        return { status: true, ft_id: data.Records.ft_id };
      } else {
        dispatch({ type: FNB_BOOKING_ERROR, payload: data.message });
        return { status: false, error: data.message };
      }
    } catch (error) {
      dispatch({ type: FNB_BOOKING_ERROR, payload: error.message });
      return { status: false, error: error.message };
    }
  };

export const bookFnbGetDataObj =
  ({
    reservation_id,
    selectedFnbItems,
    cinemaObj,
    currency_id,
    ft_total_amount,
    ft_net_amount,
    email,
    mobile,
    countryMobCode,
    ft_currency_name,
    payment_mode = null,
  }) =>
  async (dispatch, getState) => {
    try {
      let state = getState();

      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;

      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      // let email = loggedInUser?.cust_email
      // let guest_email = guestUser?.guest_email

      let optionalPayload = {};

      if (!loggedInUser && guestUser) {
        optionalPayload.is_guest_user = 'Y';
        optionalPayload.guest_details = {
          guest_email: guestUser.guest_email,
          guest_user_id: guestUser.guest_user_id,
          guest_mobile: guestUser.guest_mobile,
          guest_country_code: guestUser.guest_country_code,
        };
      }

      let scheduleDetails = state.movies.scheduleDetails;

      let showtime = `${moment(scheduleDetails?.ss_actual_start_date_time).format(
        'YYYY-MM-DD',
      )} ${moment(scheduleDetails?.ss_start_show_time, 'HH:mm:ss').format(
        'HH:mm',
      )}`;

      let fnbItemList = window.structuredClone
        ? window.structuredClone(selectedFnbItems)
        : selectedFnbItems;
      fnbItemList.map((item) => (item.quantity = item.Quantity));

      return {
        ...optionalPayload,
        isGroupRateCard: true,
        reservation_id,
        fnbItemList,
        payment_mode_id: !!payment_mode
          ? payment_mode?.pm_id
          : stripePaymentMode.pm_id,
        cinema_id: cinemaObj.cinema_id,
        currency_id,
        ft_total_amount,
        ft_net_amount,
        ft_order_status: 3,
        aggregator_cinema: cinemaObj,
        offer: { t_offer_price: 0 },
        payment_mode: !!payment_mode ? payment_mode : stripePaymentMode,
        booking_source_id: 2,
        fst_booking_source: 'Online',
        fnb_delivery_time: showtime,
        customer_details: {
          email,
          country_code: countryMobCode,
          mobile,
        },
        ft_currency_name,
        send_email: false,
      };
    } catch (error) {
      dispatch({ type: FNB_BOOKING_ERROR, payload: error.message });
      return {};
    }
  };
