import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';
import useIsTablet from '../../customHooks/useIsTablet';
import useIsDesktop from '../../customHooks/useIsDesktop';

// Images
import { ReactComponent as Facebook } from '@assets/pictures/svgs/facebook-active.svg';
import { ReactComponent as Instagram } from '@assets/pictures/svgs/instagram-active.svg';
import { ReactComponent as Twitter } from '@assets/pictures/svgs/twitter-active.svg';
import logoSample from '@assets/pictures/logo-colored.png';
import { ReactComponent as Send } from '@assets/pictures/svgs/send.svg';
import { ReactComponent as HomeIcon } from '@assets/pictures/svgs/home_new.svg';
import { ReactComponent as TicketIcon } from '@assets/pictures/svgs/ticket_new.svg';
import { ReactComponent as ProfileIcon } from '@assets/pictures/svgs/profile__new.svg';
import { Container, Row, div, Dropdown, Modal } from 'react-bootstrap';
import MobileBottomMenu from '@components/partials/MobileBottomMenu.jsx';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { CityService, LanguageService } from '@apiService/tokenService';
import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from '@plugins/i18n';
import { motion } from 'framer-motion';
import GenericService from '@apiService/GenericService';
import cinepolLogo from '@assets/pictures/cinepol_logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { titleCase } from '@helper/formatting';
import { setShowLoginPopup } from '@store/auth/authActions';
import AppStore from '@assets/pictures/footer_download_app1.jpeg';
import PlayStore from '@assets/pictures/footer_download_app2.jpeg';
// import DolByImage from '@assets/pictures/footer_dolby.jpeg';
// import PhfImage from '@assets/pictures/footer_img3.jpeg';
import PhfImage from '@assets/pictures/phf_new.jpeg';
import DolByImage from '@assets/pictures/dolby_new.jpeg';
import Facebook1 from '@assets/pictures/facebook.jpeg';
import Twitter1 from '@assets/pictures/twitter.jpeg';
import Instagram1 from '@assets/pictures/instagram.jpeg';
import FooterLogo from '@assets/pictures/footer_logo1.jpeg';

function Footer({ t }) {
  const dispatch = useDispatch();

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser } = loginDetails;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const newsLetterEmail = useRef();
  const subscribeMsg = useRef();
  const organization = useSelector((state) => state.movies.organizationDetails);

  const social_media = useSelector((state) => state.homepage.social_media_cms);
  const footer_cms = useSelector((state) => state.homepage.footer_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);
  const [showBottomMenu, setShowBottomMenu] = useState(true);

  const { org_logo_url, org_mycinema_logo, org_name } = organization;
  const { pathname } = useLocation();

  const handleLoginPopup = (toShow) => {
    dispatch(setShowLoginPopup(toShow));
  };

  useEffect(() => {
    // console.log("social_media", social_media);
  }, [social_media]);

  useEffect(() => {}, [pathname]);

  const currentTab = (path) => {
    let tab = '';
    if (path.startsWith('/home') || path.startsWith('/')) tab = 'homeTab';
    if (path.startsWith('/schedules')) tab = 'scheduleTab';
    if (path.startsWith('/guest-user-profile') || path.startsWith('/user-profile'))
      tab = 'userTab';

    return tab;
  };

  async function subscribe() {
    const payload = {
      text: newsLetterEmail.current.value,
    };
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        payload.text,
      )
    ) {
      const response = await GenericService.GetNewsLetter(payload);
      if (response.status === 200 && response.data.status === true) {
        subscribeMsg.current.innerText = t('Thank you for subscribing');
        subscribeMsg.current.className = 'text-success text-success-center';
      } else {
        subscribeMsg.current.innerText = t('You have already subscribed!');
        subscribeMsg.current.className = 'text-warning text-warning-center';
      }
      console.log(response);
    } else {
      subscribeMsg.current.innerText = t('Please enter a valid email');
      subscribeMsg.current.className = 'text-danger text-danger-center';
    }
  }

  const showItem = (item) => {
    if (!CMSLOADER) {
      if (footer_cms && footer_cms.length > 0) {
        if (footer_cms[0][item] == 'Y') {
          return true;
        } else if (footer_cms[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (social_media && social_media.length > 0) {
        if (social_media[0][item]) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const openUrl = (url) => {
    console.log('i am here', url);
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  if (
    pathname.startsWith('/schedules') ||
    pathname.startsWith('/fnb') ||
    pathname.startsWith('/create-show') ||
    pathname.startsWith('/screening-type') ||
    pathname.startsWith('/seat-layout') ||
    pathname.startsWith('/payment') ||
    pathname.includes('booking') ||
    pathname.startsWith('/final-ticket') ||
    pathname.startsWith('/unpaid-ticket') ||
    pathname.startsWith('/gift-card-details') ||
    pathname.startsWith('/gift-card-payment') ||
    pathname.startsWith('/gift-card-topup')

    // ||
    // (pathname.startsWith("/movie-details") &&
    //   pathname.split("/").length >= 5 &&
    //   pathname.split("/")[4] !== "2")
  ) {
    return null;
  }
  const CinemasValid = () => {
    if (
      window.location.href.includes('star') ||
      window.location.href.includes('localhost')
    ) {
      return true;
    }
    return false;
  };

  console.log(CinemasValid(), 'CinemasValid');
  return (
    <>
      <style>
        {`.random>h6{
            margin-bottom:10px !important;
          }`}
      </style>
      <footer className="footer_new">
        {isMobile ? (
          // Mobile Design
          <>
            {showBottomMenu && (
              <div className="container-fluid">
                <section className="row">
                  <article className="col-12">
                    <div className="footer_new_icon_links">
                      <HomeIcon
                        className={
                          currentTab(pathname) === 'homeTab' ? 'active' : ''
                        }
                        onClick={() => {
                          history.push('/');
                        }}
                      />
                      <TicketIcon
                        className={
                          currentTab(pathname) === 'scheduleTab' ? 'active' : ''
                        }
                        onClick={() => {
                          history.push('/schedules');
                        }}
                      />
                      {/*{!loggedInUser && guestUser && (
                      <p onClick={() => history.push("/guest-user-profile")}>
                        {t("My Profile")}
                      </p>
                    )}
                    {loggedInUser && (
                      <p onClick={() => history.push("/user-profile")}>
                        {t("My Profile")}
                      </p>
                    )}*/}
                      <ProfileIcon
                        className={
                          currentTab(pathname) === 'userTab' ? 'active' : ''
                        }
                        onClick={() => {
                          if (!loggedInUser && guestUser) {
                            history.push('/guest-user-profile');
                          } else if (loggedInUser) {
                            history.push('/user-profile');
                          } else {
                            handleLoginPopup(true);
                          }
                        }}
                      />
                    </div>
                  </article>
                </section>
              </div>
            )}
          </>
        ) : isDesktop ? (
          // Desktop
          <div className="container-fluid">
            <section className="row">
              <article className="col-12">
                <div
                  className="d-flex flex-wrap justify-content-center"
                  style={{ borderTop: '1px solid #1f1c3c', padding: '15px' }}
                >
                  {CinemasValid && (
                    <div style={{}} className="col-xl-4 col-md-12">
                      <h5 className="font-weight-bold text-left mb-0 footer_heading">
                        Partners
                      </h5>
                      <div
                        style={{ margin: '10px 0px' }}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <img
                          src={PhfImage}
                          alt="PHF Image"
                          className="partner_img"
                          style={{ marginLeft: isDesktop ? '-20px' : '' }}
                        ></img>

                        <img
                          src={DolByImage}
                          alt="DolBy Image"
                          className="partner_img"
                        ></img>
                      </div>
                    </div>
                  )}

                  <div
                    style={{ alignItems: 'center' }}
                    className="col-xl-4 col-md-12"
                  >
                    {showItem('Logo') && (
                      <figure
                        style={{
                          alignItems: 'center',
                        }}
                        className="footer_new_logo d-flex justify-content-center"
                        onClick={() => history.push('/')}
                      >
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          // src={
                          //   window.location.hostname.includes('cinepol'.toLowerCase())
                          //     ? cinepolLogo
                          //     : org_mycinema_logo || org_logo_url
                          // }
                          alt={org_name}
                          className=""
                        />
                      </figure>
                    )}

                    <figure className="sm_icons">
                      {showItem('Social Links') && (
                        <div className="footer-sm-links py-0">
                          {isItemActive('facebok_link') && (
                            <Facebook
                              onClick={() => openUrl(social_media[0]?.facebok_link)}
                            />
                          )}
                          {isItemActive('insta_link') && (
                            <Instagram
                              onClick={() => openUrl(social_media[0]?.insta_link)}
                            />
                          )}
                          {isItemActive('twitter_link') && (
                            <Twitter
                              onClick={() => openUrl(social_media[0]?.twitter_link)}
                            />
                          )}
                        </div>
                      )}
                      {/* <Facebook />
          <Instagram/> */}
                    </figure>
                    <div className="random">
                      <h6 className="font-weight-bold text-center">
                        Find out about the new movies and receive special promos and
                        offers
                        {/* {t('Get Latest Movie Update')} */}
                      </h6>
                      <label
                        className="footer_new_newsletter d-flex justify-content-center align-items-center"
                        style={{ textAlign: 'center' }}
                      >
                        <input
                          type="email"
                          name=""
                          id=""
                          className=""
                          placeholder={t('Enter your Email ID')}
                          ref={newsLetterEmail}
                        />
                        <Send
                          onClick={(e) => {
                            e.preventDefault();
                            subscribe();
                            newsLetterEmail.current.value = '';
                          }}
                        />
                      </label>
                      <p ref={subscribeMsg} />
                      {/* <p style={{ textAlign: 'center', color:'#1f1c3c',padding:'5px' }}>Thank you for subscribing.</p> */}
                    </div>
                  </div>
                  {CinemasValid && (
                    <div className="col-xl-4 col-md-12">
                      <h5 className="font-weight-bold text-right mb-0 footer_heading">
                        {' '}
                        Download Your App Here
                      </h5>

                      <div
                        style={{ margin: '10px 0px' }}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <img
                          src={AppStore}
                          alt="App Store"
                          width={170}
                          onClick={() =>
                            openUrl(
                              'https://apps.apple.com/ae/app/star-cinemas/id1568091870',
                            )
                          }
                          className="app_img_apple"
                        ></img>

                        <img
                          src={PlayStore}
                          alt="Play Store"
                          width={170}
                          onClick={() =>
                            openUrl(
                              'https://play.google.com/store/apps/details?id=com.pharsfilm.starcinemasuae',
                            )
                          }
                          className="app_img_play"
                        ></img>
                      </div>
                      <div className="">
                        <div
                          className="mb-2 footer_heading"
                          style={{ textAlign: 'right' }}
                        >
                          Stay In Touch
                        </div>
                        <div
                          className="d-flex justify-content-end align-items-center"
                          style={{ gap: '7px' }}
                        >
                          <img
                            src={Facebook1}
                            alt="Facebook"
                            className="icons_img"
                            onClick={() =>
                              openUrl('https://www.facebook.com/starcinemasme/')
                            }
                          ></img>

                          <img
                            src={Twitter1}
                            alt="Twitter"
                            className="icons_img"
                            onClick={() =>
                              openUrl(
                                'https://twitter.com/starcinemasme?ref_src=twsrc%5Etfw',
                              )
                            }
                          ></img>

                          <img
                            src={Instagram1}
                            alt="Instagram"
                            className="icons_img"
                            onClick={() =>
                              openUrl('https://www.instagram.com/starcinemasme/')
                            }
                          ></img>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      {showItem('About US') && (
                        <Link to="/about-us" className="footer_new_link">
                          {t('common.About Us')}
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Contact US') && (
                        <Link to="/contact-us" className="footer_new_link">
                          {t('Contact Us')}
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('FAQs') && (
                        <Link to="/faqs" className="footer_new_link">
                          {t('common.FAQs')}
                        </Link>
                      )}
                    </li>

                    <li className="list-inline-item">
                      {showItem('Advertise With Us') && (
                        <Link to="/AdvertiseWithUs" className="footer_new_link">
                          Advertise With Us
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Whatsup Community') && (
                        <Link to="/WhatsappCommunity" className="footer_new_link">
                          Whatsapp Community
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Terms n Conditions') && (
                        <Link to="/tnc" className="footer_new_link">
                          {t('common.Terms and Conditions')}
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Privacy Policy') && (
                        <Link to="/privacy-policy" className="footer_new_link">
                          {t('common.Privacy Policy')}
                        </Link>
                      )}
                    </li>
                  </ul>
                  <h6 style={{ textAlign: 'center' }}>
                    &copy; {t('All Rights Reserved')}
                  </h6>
                </div>
                <div className="footer_new_items">
                  {/* <figure className="footer_new_logo">
               {showItem("Logo") ? (
                      <Link className="mb-0" to="/">
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          alt={org_name}
                          // width="115px"
                          width="200px"
                          className=""
                        />
                      </Link>
                    ) : (
                      <div
                        className="dummy-image"
                        style={{
                          width: "100px",
                        }}
                      ></div>
                    )}
        </figure> */}
                </div>
              </article>
            </section>
          </div>
        ) : (
          <div className="container-fluid">
            <section className="row">
              <article className="col-12">
                <div
                  className="d-flex flex-wrap justify-content-center"
                  style={{ borderTop: '1px solid #1f1c3c', padding: '15px' }}
                >
                  <div
                    style={{ alignItems: 'center' }}
                    className="col-xl-4 col-md-12"
                  >
                    {showItem('Logo') && (
                      <figure
                        style={{
                          alignItems: 'center',
                        }}
                        className="footer_new_logo d-flex justify-content-center"
                        onClick={() => history.push('/')}
                      >
                        <img
                          src={org_mycinema_logo || org_logo_url || FooterLogo}
                          // src={
                          //   window.location.hostname.includes('cinepol'.toLowerCase())
                          //     ? cinepolLogo
                          //     : org_mycinema_logo || org_logo_url
                          // }
                          alt={org_name}
                          className=""
                        />
                      </figure>
                    )}

                    <figure className="sm_icons mb-0">
                      {showItem('Social Links') && (
                        <div className="footer-sm-links py-0">
                          {isItemActive('facebok_link') && (
                            <Facebook
                              onClick={() => openUrl(social_media[0]?.facebok_link)}
                            />
                          )}
                          {isItemActive('insta_link') && (
                            <Instagram
                              onClick={() => openUrl(social_media[0]?.insta_link)}
                            />
                          )}
                          {isItemActive('twitter_link') && (
                            <Twitter
                              onClick={() => openUrl(social_media[0]?.twitter_link)}
                            />
                          )}
                        </div>
                      )}
                      {/* <Facebook />
          <Instagram/> */}
                    </figure>
                    <div className="random">
                      <h6 className="font-weight-bold text-center mb-2">
                        Find out about the new movies and receive special promos and
                        offers
                        {/* {t('Get Latest Movie Update')} */}
                      </h6>
                      <label
                        className="footer_new_newsletter d-flex justify-content-center align-items-center"
                        style={{ textAlign: 'center' }}
                      >
                        <input
                          type="email"
                          name=""
                          id=""
                          className=""
                          placeholder={t('Enter your Email ID')}
                          ref={newsLetterEmail}
                        />
                        <Send
                          onClick={(e) => {
                            e.preventDefault();
                            subscribe();
                            newsLetterEmail.current.value = '';
                          }}
                        />
                      </label>
                      <p ref={subscribeMsg} />
                    </div>
                  </div>

                  <div style={{}} className="col-xl-4 col-md-4 mt-3">
                    <h5 className="font-weight-bold text-center mb-0">Partners</h5>
                    <div
                      style={{ margin: '10px 0px' }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src={PhfImage}
                        alt="PHF Image"
                        className="partner_img"
                      ></img>

                      <img
                        src={DolByImage}
                        alt="DolBy Image"
                        className="partner_img"
                      ></img>
                    </div>
                  </div>

                  <div className="col-xl-4 col-md-4 mt-3">
                    <h5 className="font-weight-bold text-center mb-0">
                      {' '}
                      Download Your App Here
                    </h5>

                    <div
                      style={{ margin: '10px 0px' }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src={AppStore}
                        alt="App Store"
                        width={170}
                        onClick={() =>
                          openUrl(
                            'https://apps.apple.com/ae/app/star-cinemas/id1568091870',
                          )
                        }
                        className="app_img"
                      ></img>

                      <img
                        src={PlayStore}
                        alt="Play Store"
                        width={170}
                        onClick={() =>
                          openUrl(
                            'https://play.google.com/store/apps/details?id=com.pharsfilm.starcinemasuae',
                          )
                        }
                        className="app_img"
                      ></img>
                    </div>
                  </div>

                  <div className="col-xl-4 col-md-4 mt-3">
                    <h5 className="font-weight-bold text-center mb-0">
                      {' '}
                      Stay In Touch
                    </h5>

                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ gap: '15px', margin: '10px 0px' }}
                    >
                      <img
                        src={Facebook1}
                        alt="Facebook"
                        className="icons_img"
                        onClick={() =>
                          openUrl('https://www.facebook.com/starcinemasme/')
                        }
                      ></img>

                      <img
                        src={Twitter1}
                        alt="Twitter"
                        className="icons_img"
                        onClick={() =>
                          openUrl(
                            'https://twitter.com/starcinemasme?ref_src=twsrc%5Etfw',
                          )
                        }
                      ></img>

                      <img
                        src={Instagram1}
                        alt="Instagram"
                        className="icons_img"
                        onClick={() =>
                          openUrl('https://www.instagram.com/starcinemasme/')
                        }
                      ></img>
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      {showItem('About US') && (
                        <Link to="/about-us" className="footer_new_link">
                          {t('common.About Us')}
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Contact US') && (
                        <Link to="/contact-us" className="footer_new_link">
                          {t('Contact Us')}
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('FAQs') && (
                        <Link to="/faqs" className="footer_new_link">
                          {t('common.FAQs')}
                        </Link>
                      )}
                    </li>

                    <li className="list-inline-item">
                      {showItem('Advertise With Us') && (
                        <Link to="/AdvertiseWithUs" className="footer_new_link">
                          Advertise With Us
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Whatsup Community') && (
                        <Link to="/whatsup_community" className="footer_new_link">
                          Whatsup Community
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Terms n Conditions') && (
                        <Link to="/tnc" className="footer_new_link">
                          {t('common.Terms and Conditions')}
                        </Link>
                      )}
                    </li>
                    <li className="list-inline-item">
                      {showItem('Privacy Policy') && (
                        <Link to="/privacy-policy" className="footer_new_link">
                          {t('common.Privacy Policy')}
                        </Link>
                      )}
                    </li>
                  </ul>
                  <h6 style={{ textAlign: 'center' }}>
                    &copy; {t('All Rights Reserved')}
                  </h6>
                </div>
                <div className="footer_new_items">
                  {/* <figure className="footer_new_logo">
               {showItem("Logo") ? (
                      <Link className="mb-0" to="/">
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          alt={org_name}
                          // width="115px"
                          width="200px"
                          className=""
                        />
                      </Link>
                    ) : (
                      <div
                        className="dummy-image"
                        style={{
                          width: "100px",
                        }}
                      ></div>
                    )}
        </figure> */}
                </div>
              </article>
            </section>
          </div>
        )}
      </footer>
    </>
  );
}
export default withNamespaces()(Footer);
