import _ from 'lodash';
import {
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_SUCCESS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_MOVIE_SCHEDULES,
  FETCH_MOVIE_SCHEDULES_SUCCESS,
  FETCH_MOVIE_SCHEDULES_ERROR,
  ON_SET_COMPLETE_RATE_CARD,
  ON_SET_COMPLETE_NEW_TOTAL_AMOUNT,
  FETCH_SCHEDULE_DETAILS,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
  FETCH_SCHEDULE_DETAILS_ERROR,
  FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
  SET_STAGE,
  SET_BOOKING_TYPE,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_SUCCESS,
  SET_SHOW_BOOKING_ID,
  FETCH_BOOKING_DETAILS_ERROR,
  BOOK_SEAT_SUCCESS,
  SET_STRIPE_PUBLISHER_KEY,
  FETCH_OTHER_SCREENINGS,
  FETCH_OTHER_SCREENINGS_SUCCESS,
  FETCH_OTHER_SCREENINGS_ERROR,
  FETCH_GLOBAL_OPTIONS,
  FETCH_GLOBAL_OPTIONS_SUCCESS,
  FETCH_GLOBAL_OPTIONS_ERROR,
  FETCH_CURRENCY,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_ERROR,
  FETCH_SCREENING_TYPES,
  FETCH_SCREENING_TYPES_SUCCESS,
  FETCH_SCREENING_TYPES_ERROR,
  SET_PRIVATE_SCREEN_AMOUNT,
  START_TIMER,
  RESET_TIMER,
  FETCH_MOVIE_DETAILS_SUCCESS2,
  SET_FROM_MOVIE_PAGE,
  CREATE_SHOW,
  CREATE_SHOW_SUCCESS,
  CREATE_SHOW_ERROR,
  SET_SHOW,
  SET_TIMEOUT_ERROR,
  FETCH_RESERVATION_DETAILS,
  FETCH_RESERVATION_DETAILS_SUCCESS,
  FETCH_RESERVATION_DETAILS_ERROR,
  SET_TIMER,
  CLEAR_STATE,
  FETCH_MOVIE_SCHEDULES_SUCCESS2,
  FETCH_MOVIE_SCHEDULES_SUCCESS3,
  STOP_TIMER,
  BOOK_SEAT_ERROR,
  SET_SELECTED_LANGUAGE,
  FETCH_NOW_SHOWING,
  FETCH_NOW_SHOWING_SUCCESS,
  FETCH_NOW_SHOWING_ERROR,
  FETCH_CINEMAS,
  FETCH_CINEMAS_SUCCESS,
  FETCH_CINEMAS_ERROR,
  SELECT_CINEMA_SUCCESS,
  SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS,
  SEND_SMS_ERROR,
  SEND_EMAIL_ERROR,
  SET_IS_CREATE_YOUR_SHOW_ENABLED,
  SET_MOVIE_FORMATS,
  SET_MOVIE_LANGUAGES,
  FETCH_ALL_MOVIES_SCHEDULES,
  FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
  FETCH_ALL_MOVIES_SCHEDULES_ERROR,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_ERROR,
  SET_PRIVATE_SCREENING_TYPE_PAYLOAD,
  SET_ACTIVE_PAYMENT_MODES,
  SET_SAFER_PAY_ACTIVE,
  SET_PAY_DISABLE_SAFER_PAY,
  CLEAR_MOVIE_SCHEDULES,
  ALLOW_WEBSITE_BOOKING,
  MAX_SEAT_SELECTION,
  SET_LABEL_MOVIES,
  SET_LABEL_DATA,
  FETCH_LABEL_MOVIES,
  SET_ALL_LABELS,
  USER_SELECTION_SELECT_DATE,
  USER_SELECTION_SELECT_SHOW,
  USER_SELECTION_SELECT_SESSION,
  USER_SELECTION_SELECT_LANG,
  USER_SELECTION_SELECT_SCREENING_TYPE,
  RESET_USER_SELECTION,
  ORGANIZATION_THEME,
  SELECT_CITY_SUCCESS,
  FETCH_CITIES_SUCCESS,
  SET_IS_ADVANCE,
} from './movieTypes';

let INITIAL_MINS = 4;
let INITIAL_SECONDS = 59;

let initialState = {
  isAdvance: false,
  userSelection: {
    selectedDate: null,
    selectedSession: null,
    selectedShow: null,
    selectedScreen: null,
    selectedMovieVersion: null,
    selectedLang: null,
    selectedScreeningType: null,
    payNowTotal: null,
  },
  nowShowing: null,
  nowShowingLoading: false,
  nowShowingError: null,

  movieDetails: [],
  movieDetailsLoading: false,
  movieDetailsError: null,

  movieSchedules: null,
  movieScheduleDetails: null,
  movieSchedulesLoading: false,
  movieSchedulesError: null,
  movieFormats: [],
  movieLanguages: [],
  movieLanguagesWithSubs: [],
  isCompleteRateCard: false,
  seatGroupAll: null,
  ft_id: null,
  totalAmount: null,
  netAmount: null,
  selectedTicketPrice: {
    priceInCents: 0,
    taxInCents: 0,
    totalWithFnb: 0,
    taxWithFnb: 0,
  },
  scheduleDetails: null,
  scheduleDetailsLoading: false,
  scheduleDetailsError: null,
  stage: 1,
  show: null,
  booking_type: null,
  bookingDetails: null,
  bookingDetailsLoading: false,
  bookingDetailsError: null,
  showBookingID: null,
  bookingSuccessful: false,
  isUnpaidBooking: false,
  stripePublisherKey: null,

  otherScreenings: [],
  otherScreeningsLoading: false,
  otherScreeningsError: null,
  currency: {},
  loading: false,
  error: null,
  globalSettings: {},
  globalOptions: [],
  screeningTypes: [],
  screeningTypesLoading: false,
  screeningTypesError: null,
  privateScreeningTypePayload: null,
  timer: {
    minutes: INITIAL_MINS,
    seconds: INITIAL_SECONDS,
  },
  timerCompleted: false,
  fromMoviePage: false,
  myCinemaCreateShow: {},
  timeoutError: false,
  reservationDetails: null,
  reservationDetailsLoading: null,
  dateMovieScheduleDetails: null,
  dateMovieScheduleDetailsLoading: true,
  filterMovieScheduleDetails: null,
  bookSeatsError: null,
  selectedCinema: JSON.parse(localStorage.getItem(`selected_cinema`)) || null,
  selectedCity: JSON.parse(localStorage.getItem(`selected_city`)) || null,
  cinemas: [],
  cities: [],
  getLanguageSpecificMovieDetails: null,
  getLanguageSpecificGenres: null,
  getLanguageSpecificLanguages: null,
  resendSMSError: null,
  resendEmailError: null,
  movieDetailsCopy: null,
  selected_language: JSON.parse(localStorage.getItem('selected_language')) || {
    lang_id: 1,
    lang_name: 'English',
    alternate_lang_name: null,
    lang_is_active: 'Y',
    created_by: null,
    created_at: null,
    updated_by: 1,
    updated_at: '2021-03-09T11:05:00.000Z',
    iso_2: 'en',
    iso_3: 'en',
  },
  createYourShowEnabled: false,
  createYourShowPrice: null,
  allMoviesSchedulesLoading: false,
  allMoviesSchedules: {
    movieSchedules: [],
    datesArray: [],
  },
  allMoviesSchedulesError: null,
  allMoviesSchedulesLanguages: [],
  organizationDetails: {},
  organizationTheme: 0,
  activePaymentModes: [],
  isSaferPayActive: false,
  isSaferPayButtonDisable: false,
  allow_website_booking: true,
  max_seat_selection: 10,
  label_movies: [],
  label_data: [],
  allLabels: [],
  nowShowingLabelMovies: [],
  onDemandLabelMovies: [],
  upcomingLabelMovies: [],
  labelMoviesLoading: false,
};

const movieReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_SMS_ERROR:
      return { ...state, resendSMSError: payload };
    case SEND_EMAIL_ERROR:
      return { ...state, resendEmailError: payload };

    case SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS:
      // console.log(state, `manav 1234`);
      const {
        selected_language: { lang_name },
        movieDetails,
      } = state;
      let getLanguageSpecificMovieDetails;

      switch (lang_name) {
        case 'English':
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === 'English',
          );

          break;
        case 'German':
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === 'German',
          );
          break;
        case 'Italian':
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === 'Italian',
          );
          break;
        case 'French':
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === 'French',
          );
          break;
        case 'Hindi':
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === 'Hindi',
          );
          break;
        default:
          getLanguageSpecificMovieDetails = movieDetails;
      }
      if (getLanguageSpecificMovieDetails === undefined) {
        getLanguageSpecificMovieDetails = movieDetails;
      }
      return { ...state, getLanguageSpecificMovieDetails };

    case FETCH_CINEMAS:
      return {
        ...state,
        cinemas: [],
        cities: [],
      };
    case FETCH_CINEMAS_SUCCESS:
      return {
        ...state,
        cinemas: payload,
      };
    case FETCH_CINEMAS_ERROR:
      return {
        cinemas: [],
        cities: [],
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        cities: payload,
      };
    case FETCH_ORGANIZATION:
      return {
        ...state,
        organizationDetails: {},
      };
    case FETCH_ORGANIZATION:
      return {
        ...state,
        organizationDetails: {},
      };
    case FETCH_LABEL_MOVIES:
      return {
        ...state,
        label_movies: [],
        labelMoviesLoading: true,
      };
    case SET_ALL_LABELS:
      return {
        ...state,
        allLabels: payload,
      };
    case SET_LABEL_MOVIES:
      let temp_nowLabelMovies = payload.filter((x) => x.now_showing_count > 0);
      let temp_onDemandLabelMovies = payload.filter((x) => x.now_demand_count > 0);
      let temp_upcomingMovies = payload.filter((x) => x.is_upcomimg);
      return {
        ...state,
        label_movies: payload,
        onDemandLabelMovies: temp_onDemandLabelMovies,
        nowShowingLabelMovies: temp_nowLabelMovies,
        upcomingLabelMovies: temp_upcomingMovies,
        labelMoviesLoading: false,
      };
    case SET_LABEL_DATA:
      return {
        ...state,
        label_data: payload,
        labelMoviesLoading: false,
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationDetails: payload,
      };
    case FETCH_ORGANIZATION_ERROR:
      return {
        organizationDetails: {},
      };
    case SELECT_CINEMA_SUCCESS:
      localStorage.setItem(`selected_cinema`, JSON.stringify(payload));
      return { ...state, selectedCinema: payload };
    case SELECT_CITY_SUCCESS:
      localStorage.setItem(`selected_city`, JSON.stringify(payload));
      return { ...state, selectedCity: payload };
    case SET_SELECTED_LANGUAGE:
      let lang = {
        ...payload,
      };

      if (!lang.alternate_lang_name) {
        lang.alternate_lang_name = lang.lang_name;
      }

      if (!lang.iso_2) {
        lang.iso_2 = 'EN';
      }

      if (!lang.iso_3) {
        lang.iso_2 = 'ENG';
      }

      localStorage.setItem(`selected_language`, JSON.stringify(lang));
      return {
        ...state,
        selected_language: lang,
      };
    case BOOK_SEAT_ERROR:
      return { ...state, bookSeatsError: payload };
    case BOOK_SEAT_SUCCESS:
      let isUnpaidBooking = payload?.isUnpaidBooking ?? false;
      return {
        ...state,
        bookingSuccessful: true,
        isUnpaidBooking,
      };
    case FETCH_MOVIE_SCHEDULES_SUCCESS3:
      return { ...state, filterMovieScheduleDetails: payload };
    case FETCH_MOVIE_SCHEDULES_SUCCESS2:
      let reducedPayload = window.structuredClone
        ? window.structuredClone(payload)
        : payload;
      if (
        reducedPayload?.dateList?.length > 0 &&
        reducedPayload?.dateList[0].hasOwnProperty('isDateAvailable')
      ) {
        // filtering unavailable dates for ondemand
        reducedPayload.dateList = [
          ...(reducedPayload?.dateList?.filter((x) => x.isDateAvailable === true) ||
            []),
        ];
      }

      return {
        ...state,
        dateMovieScheduleDetails: reducedPayload,
        dateMovieScheduleDetailsLoading: false,
      };
    case CLEAR_STATE:
      let currency = state.currency;
      let organizationDetails = state.organizationDetails; // preserving org details
      let globalOptions = state.globalOptions; // preserving details
      let globalSettings = state.globalSettings; // preserving details
      return {
        ...initialState,
        currency,
        organizationDetails,
        globalSettings,
        globalOptions,
      };
    case CLEAR_MOVIE_SCHEDULES:
      return {
        ...state,
        movieSchedules: initialState.movieSchedules,
        filterMovieScheduleDetails: initialState.filterMovieScheduleDetails,
        dateMovieScheduleDetails: initialState.dateMovieScheduleDetails,
        movieSchedulesLoading: initialState.movieSchedulesLoading,
        dateMovieScheduleDetailsLoading:
          initialState.dateMovieScheduleDetailsLoading,
        // createYourShowEnabled: false,
        movieLanguages: [],
        movieLanguagesWithSubs: [],
        movieFormats: [],
      };
    case SET_TIMEOUT_ERROR:
      return { ...state, timeoutError: payload };
    case SET_SHOW:
      return { ...state, show: payload };
    case FETCH_RESERVATION_DETAILS:
      return {
        ...state,
        reservationDetails: null,
        reservationDetailsLoading: true,
      };
    case FETCH_RESERVATION_DETAILS_ERROR:
      return {
        ...state,
        reservationDetails: null,
        reservationDetailsLoading: false,
      };
    case FETCH_RESERVATION_DETAILS_SUCCESS:
      return {
        ...state,
        reservationDetails: payload,
        reservationDetailsLoading: false,
      };
    case CREATE_SHOW:
    case CREATE_SHOW_ERROR:
      return {
        ...state,
        myCinemaCreateShow: {},
      };
    case CREATE_SHOW_SUCCESS:
      return {
        ...state,
        myCinemaCreateShow: payload,
      };
    case FETCH_NOW_SHOWING:
      return {
        ...state,
        nowShowing: null,
        nowShowingLoading: true,
      };
    case FETCH_NOW_SHOWING_SUCCESS:
      // console.log("payload manav :>> ", payload);
      return {
        ...state,
        nowShowing:
          state.nowShowing === null
            ? payload
            : {
                ...state.nowShowing,
                data: [...state.nowShowing.data, ...payload.data],
              },
        nowShowingLoading: false,
      };
    case FETCH_NOW_SHOWING_ERROR:
      return {
        ...state,
        nowShowing: null,
        nowShowingError: payload,
      };
    case SET_PRIVATE_SCREEN_AMOUNT:
      return {
        ...state,
        totalAmount: payload.amount,
        netAmount: payload.net_amount,
      };
    case FETCH_MOVIE_DETAILS:
      return {
        ...state,
        movieDetails: null,
        movieDetailsLoading: true,
        movieLanguages: [],
        movieLanguagesWithSubs: [],
        movieFormats: [],
      };
    case FETCH_MOVIE_DETAILS_SUCCESS2:
      return {
        ...state,
        movieDetailsCopy: payload,
      };
    case FETCH_MOVIE_DETAILS_SUCCESS:
      return {
        ...state,
        movieDetails: payload,
        movieDetailsLoading: false,
      };
    case FETCH_MOVIE_DETAILS_ERROR:
      return {
        ...state,
        movieDetails: null,
        movieDetailsError: payload,
        movieDetailsLoading: false,
      };

    case FETCH_MOVIE_SCHEDULES:
      return {
        ...state,
        movieSchedules: null,
        movieSchedulesLoading: true,
      };
    case `${FETCH_MOVIE_SCHEDULES}_LOADING`:
      return {
        ...state,
        movieSchedulesLoading: true,
      };
    case FETCH_MOVIE_SCHEDULES_SUCCESS:
      return {
        ...state,
        movieSchedules: payload,
        movieSchedulesLoading: false,
      };
    case FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS:
      return {
        ...state,
        movieScheduleDetails: payload,
      };
    case FETCH_MOVIE_SCHEDULES_ERROR:
      return { ...state, movieSchedules: null, movieSchedulesError: payload };
    case FETCH_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: null,
        scheduleDetailsLoading: true,
      };
    case FETCH_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        scheduleDetails: payload,
        scheduleDetailsLoading: false,
      };
    case FETCH_SCHEDULE_DETAILS_ERROR:
      return { ...state, scheduleDetails: null, scheduleDetailsError: payload };

    case ON_SET_COMPLETE_RATE_CARD:
      return {
        ...state,
        isCompleteRateCard: payload.status,
        seatGroupAll: window.structuredClone
          ? window.structuredClone(payload.seatGroup)
          : payload.seatGroup,
      };
    case ON_SET_COMPLETE_NEW_TOTAL_AMOUNT:
      return {
        ...state,
        selectedTicketPrice: {
          priceInCents: payload.totalAmount,
          taxInCents:
            payload.netAmount !== 0
              ? payload.totalAmount - payload.netAmount
              : state.selectedTicketPrice.taxInCents,
          totalWithFnb: state.selectedTicketPrice.totalWithFnb,
          taxWithFnb: state.selectedTicketPrice.taxWithFnb,
        },
        netAmount: payload.netAmount,
        totalAmount: payload.totalAmount,
      };
    case SET_STAGE:
      console.log('payload :>> ', payload);
      return {
        ...state,
        stage: payload.stage,
        // show: payload.show,
      };
    case SET_BOOKING_TYPE:
      return {
        ...state,
        booking_type: payload,
      };
    case SET_SHOW_BOOKING_ID:
      return {
        ...state,
        showBookingID: payload,
      };
    case FETCH_BOOKING_DETAILS:
      return {
        ...state,
        bookingDetails: null,
        bookingDetailsLoading: true,
      };
    case FETCH_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: payload,
        bookingDetailsLoading: false,
      };
    case FETCH_BOOKING_DETAILS_ERROR:
      return { ...state, bookingDetails: null, bookingDetailsError: payload };
    case SET_STRIPE_PUBLISHER_KEY:
      return { ...state, stripePublisherKey: payload };

    case FETCH_OTHER_SCREENINGS:
      return {
        ...state,
        otherScreenings: [],
        otherScreeningsLoading: true,
      };
    case FETCH_OTHER_SCREENINGS_SUCCESS:
      return {
        ...state,
        otherScreenings: payload,
        otherScreeningsLoading: false,
      };
    case FETCH_OTHER_SCREENINGS_ERROR:
      return { ...state, otherScreenings: [], otherScreeningsError: payload };
    case FETCH_GLOBAL_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GLOBAL_OPTIONS_SUCCESS:
      let guestUserFlag =
        payload.find((g) => g.go_key === `GUEST_CHECKOUT`)?.go_value ?? 'N';
      if (guestUserFlag !== 'Y') localStorage.removeItem('guest_user');

      return {
        ...state,
        loading: false,
        globalOptions: payload,
        error: null,
      };
    case FETCH_GLOBAL_OPTIONS_ERROR:
      return {
        loading: false,
        globalOptions: [],
        error: payload,
      };
    case FETCH_CURRENCY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currency: payload,
        error: null,
      };
    case FETCH_CURRENCY_ERROR:
      return {
        loading: false,
        currency: {},
        error: payload,
      };
    case ALLOW_WEBSITE_BOOKING:
      return {
        ...state,
        allow_website_booking: payload,
      };
    case MAX_SEAT_SELECTION:
      return {
        ...state,
        max_seat_selection: payload,
      };
    case FETCH_SCREENING_TYPES:
      return {
        ...state,
        screeningTypes: [],
        screeningTypesLoading: true,
      };
    case FETCH_SCREENING_TYPES_SUCCESS:
      let groupByQuantity = (obj) =>
        Object.fromEntries(
          _.chain(obj)
            .groupBy('quantity')
            .toPairs()
            .map(([key, valueArray]) => {
              return [key, valueArray];
            }),
        );

      let [privateScreening] = payload.filter((x) => x?.booking_type_id === 3);
      let rateCardPrivate = privateScreening?.rate_card_array?.[0] ?? [];

      rateCardPrivate.fnbListing.forEach((fnb) => {
        let fnbOptions = rateCardPrivate.fnbDataPricing
          .filter((f) => fnb?.item_id === f?.item_id)
          .sort((a, b) => a.quantity - b.quantity);

        fnb.priceGroupByQuantity = groupByQuantity(fnbOptions);
      });
      rateCardPrivate.addOnsData.forEach((addon) => {
        let addOnOptions = rateCardPrivate.fnbDataPricing
          .filter((f) => addon?.item_id === f?.item_id)
          .sort((a, b) => a.quantity - b.quantity);

        addon.priceGroupByQuantity = groupByQuantity(addOnOptions);
      });

      console.log({ rateCardPrivate });
      return {
        ...state,
        screeningTypes: payload,
        screeningTypesLoading: false,
      };
    case SET_PRIVATE_SCREENING_TYPE_PAYLOAD:
      return {
        ...state,
        privateScreeningTypePayload: payload,
      };
    case FETCH_SCREENING_TYPES_ERROR:
      return { ...state, screeningTypes: [], screeningTypesError: payload };
    case START_TIMER: {
      let oldTimer = state.timer;
      let newTimer = {};
      let newSeconds = oldTimer.seconds;
      let newMinutes = oldTimer.minutes;
      let timerCompleted = false;

      if (newSeconds === 0) {
        newSeconds = 59;
        if (newMinutes === 0) {
          // timer has ended
          timerCompleted = true;
          newSeconds = 0;
          newMinutes = 0;
          if (action.payload && action.payload.timerInterval) {
            clearInterval(action.payload.timerInterval);
            action.payload.timerInterval = null;
          }
        } else {
          newMinutes -= 1;
        }
      } else {
        newSeconds -= 1;
      }

      newTimer = {
        seconds: newSeconds,
        minutes: newMinutes,
      };

      return {
        ...state,
        timer: newTimer,
        timerCompleted,
      };
    }
    case STOP_TIMER:
      if (action.payload && action.payload.timerInterval) {
        clearInterval(action.payload.timerInterval);
      }
      return { ...state };
    // return{...state,
    // }
    case RESET_TIMER: {
      return {
        ...state,
        timer: {
          seconds: INITIAL_SECONDS,
          minutes: INITIAL_MINS,
        },
        timerCompleted: false,
      };
    }
    case SET_TIMER: {
      return { ...state, timer: payload };
    }

    case SET_FROM_MOVIE_PAGE: {
      return {
        ...state,
        fromMoviePage: true,
      };
    }
    case SET_IS_CREATE_YOUR_SHOW_ENABLED: {
      return {
        ...state,
        createYourShowEnabled: payload,
      };
    }
    case 'SET_IS_CREATE_YOUR_SHOW_PRICE': {
      return {
        ...state,
        createYourShowPrice: payload,
      };
    }
    case SET_MOVIE_FORMATS: {
      return {
        ...state,
        movieFormats: payload,
      };
    }
    case SET_MOVIE_LANGUAGES: {
      return {
        ...state,
        movieLanguages: payload,
      };
    }
    case `${SET_MOVIE_LANGUAGES}_WITH_SUBS`: {
      return {
        ...state,
        movieLanguagesWithSubs: payload,
      };
    }

    case FETCH_ALL_MOVIES_SCHEDULES: {
      return {
        ...state,
        allMoviesSchedulesLoading: true,
        allMoviesSchedulesError: null,
        allMoviesSchedulesLanguages: [],
      };
    }
    case FETCH_ALL_MOVIES_SCHEDULES_SUCCESS: {
      // filtering screens with no shows
      (action.payload?.movieSchedules || []).forEach((sch) => {
        let filteredScr = [];

        sch.screens.forEach((sc) => {
          let totalShows = sc?.shows?.length ?? -1;
          let count = 0;

          sc.shows.forEach((sh) => {
            if (sh?.shows?.length === 0) ++count;
          });

          if (count === totalShows) console.log('FILTER', { sc });
          else filteredScr.push(sc);
        });

        sch.screens = filteredScr;
      });

      // filtering schedules with no shows
      let filteredSch = [];
      (action.payload?.movieSchedules || []).forEach((sch) => {
        let totalSc = sch?.screens?.length ?? -1;
        let count = 0;

        sch.screens.forEach((sh) => {
          if (sch.screens?.length === 0) ++count;
        });
        if (count === totalSc) console.log('FILTER', { sch });
        else filteredSch.push(sch);
      });

      // filtering schedules with date
      filteredSch = filteredSch.sort((a, b) => {
        // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
        let comparison =
          new Date(b?.mrrdr_release_date || b?.original_release_date) -
          new Date(a?.mrrdr_release_date || a?.original_release_date);

        if (comparison === 0) comparison = b?.showCount - a?.showCount;

        return comparison;
      });

      action.payload.movieSchedules = filteredSch;

      // setting shows count for that day for all screens
      (action.payload?.movieSchedules || []).forEach((sch) => {
        sch.screens.forEach((sc) => {
          sc.shows.forEach((sh, i) => {
            if (!sch.datesArray[i].hasOwnProperty('totalShowsToday'))
              sch.datesArray[i].totalShowsToday = 0;

            sch.datesArray[i].totalShowsToday += sh?.shows?.length || 0;
          });
        });
      });
      return {
        ...state,
        allMoviesSchedulesLoading: false,
        allMoviesSchedulesError: null,
        allMoviesSchedules: action.payload,
        allMoviesSchedulesLanguages: action?.payload?.languageArray ?? [],
      };
    }
    case FETCH_ALL_MOVIES_SCHEDULES_ERROR: {
      return {
        ...state,
        allMoviesSchedulesLoading: false,
        allMoviesSchedulesError: action.payload,
        allMoviesSchedules: {
          movieSchedules: [],
          datesArray: [],
        },
        allMoviesSchedulesLanguages: [],
      };
    }
    case SET_ACTIVE_PAYMENT_MODES: {
      return {
        ...state,
        activePaymentModes: payload,
      };
    }
    case SET_SAFER_PAY_ACTIVE: {
      return {
        ...state,
        isSaferPayActive: payload,
      };
    }
    case SET_PAY_DISABLE_SAFER_PAY: {
      return {
        ...state,
        isSaferPayButtonDisable: payload,
      };
    }
    case USER_SELECTION_SELECT_DATE: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          selectedDate: payload,
          selectedShow: null,
          selectedScreen: null,
          selectedMovieVersion: null,
          selectedScreeningType: null,
        },
      };
    }
    case USER_SELECTION_SELECT_SHOW: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          selectedShow: payload?.show ?? null,
          selectedScreen: payload?.screen ?? null,
          selectedMovieVersion: payload?.movieVersion ?? null,
          selectedScreeningType: null,
        },
      };
    }
    case USER_SELECTION_SELECT_SESSION: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          selectedSession: payload,
          selectedShow: null,
          selectedScreen: null,
          selectedMovieVersion: null,
          selectedScreeningType: null,
        },
      };
    }
    case USER_SELECTION_SELECT_LANG: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          selectedLang: payload,
          selectedShow: null,
          selectedScreen: null,
          selectedMovieVersion: null,
          selectedScreeningType: null,
        },
      };
    }
    case USER_SELECTION_SELECT_SCREENING_TYPE: {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          selectedScreeningType: payload,
        },
      };
    }
    case 'PAY_NOW_TOTAL': {
      return {
        ...state,
        userSelection: {
          ...state.userSelection,
          payNowTotal: payload ?? null,
        },
      };
    }
    case RESET_USER_SELECTION: {
      return {
        ...state,
        userSelection: {
          ...initialState.userSelection,
        },
      };
    }
    case ORGANIZATION_THEME: {
      return {
        ...state,
        organizationTheme: payload,
      };
    }
    case SET_IS_ADVANCE: {
      // console.log(payload, 'tttttttttttttttttttffff');
      return {
        ...state,
        isAdvance: payload,
      };
    }
    default:
      return state;
  }
};

export default movieReducer;
