export const API_VERSION = "v1.0";
let HOST_URL = window.location.host
let backend_url = ''
if (HOST_URL == 'xanadu-mycinema.brij.tech') {
  backend_url = 'https://xanadu-api.brij.tech'
}
else if (HOST_URL == 'xanadu-cinema.brij.tech') {
  backend_url =  'https://xanadu-api.brij.tech'
}
else {
  backend_url = 'localhost'
}
export const BASEURL_BRIJ = backend_url







//const HOST_URL = `xanadu-mycinema.brij.tech`;
//const LIVEBASEURL = `https://xanadu-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL = backend_url;
//  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const BING_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjIwNTE5ZGM2LTNkODktNDViMi04OTM5LWM2Yjg4YmM2NmExNSIsImlhdCI6MTYxMjQzNzQzOSwiZXhwIjoxNjE1MDI5NDM5fQ.jMjwQUqQopck_eOQga-zTig3DDhuhnRLMpgSRo2_5s4`;
export const XANADU_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjU4ZDJlZTM4LTI4MDctNDQ4My05YzVhLThhZDYxMjI2OGY0MSIsImlhdCI6MTcxODM3MDIwOX0.1QqOnmF6hv_vLjcXQT6SaLk1EvVHVvpLEqcXiWxAURw';
export const TOKEN = XANADU_TOKEN;
