import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { sendemail } from '@store/auth/authActions';
import BreadCrumb from '@components/partials/BreadCrumbs';
import MovieShow from './partials/movieShow.jsx';

import ShowDetailsPopup from './partials/popups/showDetailsPopup.jsx';

import SeatTypePopup from './partials/popups/SeatTypePopup.jsx';
import { motion } from 'framer-motion';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';

import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from 'react-bootstrap';
import Slider from 'react-slick';

function Feedback({ t }) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [email, setEmail] = useState(``);
  const [suggestions, setSuggestions] = useState(``);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const { pathname } = useLocation();

  const isBookEvent = pathname === '/book-event';

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const stc_id =
    globalOptions.find(
      (g) => g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`,
    )?.go_value ?? 0;
  console.log({ stc_id });

  const auth = useSelector((state) => state.auth);
  const cinema = useSelector((state) => state.movies.selectedCinema);
  const { loggedInUser } = auth;
  let customer_id = loggedInUser?.cust_id || null;
  let contact_no = loggedInUser?.cust_mobile || null;
  let cinema_id = cinema?.cinema_id || null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(function fieldMarginReducer() {
    const fields = document.querySelectorAll('.fields');
    fields.forEach(function (field) {
      console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains('error')
      ) {
        field.style.marginBottom = '3px';
      }
    });
  });

  const submitForm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (email && suggestions) {
      setValidForm(true);
    }
  };

  useEffect(() => {
    if (validForm) {
      dispatch(
        sendemail({
          name: 'User from Mycinema',
          email,
          contact_no,
          message: suggestions,
          customer_id,
          cinema_id,
          stc_id,
        }),
      ).then(() => {
        setStatus(true);
      });
    }
  }, [validForm]);

  useEffect(() => {
    if (status) {
      setEmail('');
      setSuggestions('');
    }
  }, [status]);

  return (
    <>
      <div className="contact_breadcrumb">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Contact Us')} />
      </div>

      <div
        className="header-space container-fluid container-xl feedback-container"
        style={{ backgroundColor: 'var(--dark-9)' }}
      >
        <div className="row main_contact_wrapper">
          <div
            className={`feedback p-2 col-xl-4 col-md-12 ${
              isMobile || isTablet ? 'mb-4 ml-4 mr-4 mt-0' : 'm-0'
            }`}
          >
            <p className="sub-title mx-auto  text-left text-primary">HEAD OFFICE</p>
            <p className="mb-2">
              Phars Film,2705,ETA Al Manara Tower,Business Bay, Dubai
            </p>
            <p>
              Phone: +971 4 7702121
              <br />
              Email: info@pharsfilm.com
            </p>
          </div>

          <div
            className={`feedback p-2 col-xl-4 col-md-12 ${
              isMobile || isTablet ? 'mb-4 ml-4 mr-4 mt-0' : 'm-0'
            }`}
          >
            <p className="sub-title mx-auto  text-left text-primary">AJMAN</p>
            <p className="mb-2">
              <b>Star Grand Mall</b>, Sheikh Khalifa Bin Zayed St.,Ajman
            </p>
            <p>
              Phone : +971 6 7429902 <br />
              Email : star.ajman@starcinemas.com
            </p>
          </div>

          <div
            className={`feedback p-2 col-xl-4 col-md-12 ${
              isMobile || isTablet ? 'mb-4 ml-4 mr-4 mt-0' : 'm-0'
            }`}
          >
            <p className="sub-title mx-auto  text-left text-primary">
              RAS AL KHAIMAH
            </p>
            <p className="mb-2">
              Star Gulf Cinema, Al Uraibi St., Al Seer, Ras Al Khaimah
            </p>
            <p>
              Phone : +971 7 2223313 <br />
              Email : gulf@starcinemas.ae
            </p>
          </div>

          <div
            className={`feedback p-2 col-xl-4 col-md-12 ${
              isMobile || isTablet ? 'mb-4 ml-4 mr-4 mt-0' : 'ml-0 mr-0 mt-4'
            }`}
          >
            <p className="sub-title mx-auto  text-left text-primary">Al AIN</p>
            <div className="d-block">
              <div className="mb-3">
                <p className="mb-2">
                  Star Al Ain, Al Ain Mall, Othman Bin Affan St., Al Ain
                </p>
                <p>
                  Phone : +971 3 7511228 <br />
                  Email : star.alain@starcinemas.com
                </p>
              </div>

              <div className="mb-0">
                <p className="mb-2">
                  Star Bawadi Cinema, Bawadi Mall, Mazyad Rd., Al Ain
                </p>
                <p>
                  Phone : +971 3 7840300 <br />
                  Email : star.bawadi@starcinemas.com
                </p>
              </div>
            </div>
          </div>

          <div
            className={`feedback p-2 col-xl-4 col-md-12 ${
              isMobile || isTablet ? 'mb-4 ml-4 mr-4 mt-0' : 'ml-0 mr-0 mt-4'
            }`}
          >
            <p className="sub-title mx-auto  text-left text-primary">ABU DHABI</p>
            <div className="d-block">
              <div className="mb-3">
                <p className="mb-2">
                  Star Khalifa City, Central Mall, Khalifa City, Abu Dhabi
                  {/* Star Al Ain, Al Ain Mall, Othman Bin Affan St., Al Ain */}
                </p>
                <p>
                  Phone : +971 2 6275999
                  {/* Phone : +971 3 7511228 */}
                  <br />
                  Email : star.khalifacity@starcinemas.com
                  {/* Email : alain@starcinemas.ae */}
                </p>
              </div>

              <div className="mb-3">
                <p className="mb-2">
                  Star National Cinema, Al Najda St., Abu Dhabi
                  {/* Star Bawadi, Bawadi Mall, Mazyad Rd., Al Ain */}
                </p>
                <p>
                  Phone : +971 02 6289592
                  {/* Phone : +971 3 7840300 */}
                  <br />
                  Email : star.national@starcinemas.com
                  {/* Email : star.bawadi@starcinemas.com */}
                </p>
              </div>

              <div className="mb-3">
                <p className="mb-2">
                  Star Safeer, Safeer Mall, Mussafah, Abu Dhabi
                  {/* Star Khalifa City, Central Mall, Khalifa City */}
                </p>
                <p>
                  Phone : +971 2 5521515
                  {/* Phone : +971 2 6275999 */}
                  <br />
                  Email : star.mussafah@starcinemas.com
                  {/* Email : star.khalifacity@starcinemas.com */}
                </p>
              </div>

              {/* <div className="mb-3">
                <p className="mb-2">Star National Cinema, Al Najda St.</p>
                <p>
                  Phone : +971 02 6289592
                  <br />
                  Email : national@starcinemas.ae
                </p>
              </div>

              <div className="mb-3">
                <p className="mb-2">Star Safeer, Safeer Mall, Mussafah</p>
                <p>
                  Phone : +971 2 5521515
                  <br />
                  Email : mussafah@starcinemas.ae
                </p>
              </div> */}
            </div>
          </div>

          <div
            className={`feedback p-2 col-xl-4 col-md-12 ${
              isMobile || isTablet ? 'mb-4 ml-4 mr-4 mt-0' : 'ml-0 mr-0 mt-4'
            }`}
          >
            <p className="sub-title mx-auto  text-left text-primary">DUBAI</p>

            <div className="d-block">
              <div className="mb-3">
                <p className="mb-2">
                  Star Al Ghurair, Level 1, Al Ghurair Centre, Deira, Dubai
                  {/* Star Al Ghurair, Level 1, Al Ghurair Centre,Deira */}
                </p>
                <p>
                  Phone : +971 43550839
                  {/* Phone : +971 43550839 */}
                  <br />
                  Email : star.ghurair@starcinemas.com
                  {/* Email : star.ghurair@starcinemas.com */}
                </p>
              </div>

              {/* <div className="mb-3">
                <p className="mb-2">Star Cineco Galleria, Hyatt Regency, Deira</p>
                <p>
                  Phone : +971 4 2638906
                  <br />
                  Email : cinecodubai@cineco.net
                </p>
              </div> */}
            </div>

            <div
              className={`feedback  ${
                isMobile || isTablet
                  ? 'mb-0 ml-0 mr-0 mt-5 p-0'
                  : 'p-0 ml-0 mr-0 mt-4'
              }`}
            >
              <p className="sub-title mx-auto  text-left text-primary">FUJAIRAH</p>

              <div className="d-block">
                <div className="mb-3">
                  <p className="mb-2">
                    Star Century Mall, Fujairah, Al Qasar Rd., Fhujairah
                  </p>
                  <p>
                    Phone : +971 9 2236262
                    <br />
                    Email : star.century@starcinemas.com
                  </p>
                </div>

                <div className="mb-3">
                  <p className="mb-2">Star Dana Plaza, Al Ittihad Rd, Fhujairah</p>
                  <p>
                    Phone : +971 9 2243100
                    <br />
                    Email : star.dana@starcinemas.com
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`feedback p-2 col-xl-4 col-md-12 ${
              isMobile || isTablet ? 'mb-4 ml-4 mr-4 mt-0' : 'ml-0 mr-0 mt-4'
            }`}
          >
            <p
              className={`sub-title mx-auto  text-left text-primary ${
                isMobile || isTablet ? '' : 'minus_margin'
              }`}
            >
              UMM AL QUWAIN
            </p>
            <p className="mb-2">Star Mall Of UAQ, King Faisal Rd., Umm Al Quwain</p>
            <p>
              Phone : +971 6 7673966
              <br />
              Email : star.uaq@starcinemas.com
            </p>
          </div>
        </div>
      </div>
      <div className="header-space container-fluid container-xl feedback-container">
        {/* <motion.p
          className="title"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          {pathname === "/book-event" ? "Booking" : "Contact"}
        </motion.p> */}
        {/* <div className='main_wrapper_contact_us'>

</div> */}

        <motion.div
          className="feedback"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.75 }}
        >
          <p className="sub-title mx-auto">
            {pathname === '/book-event' ? t('Book an Event') : t('Contact Us')}
          </p>

          {status && (
            <div className="email-success-message">
              {t('Message sent successfully')}{' '}
            </div>
          )}

          <form onSubmit={submitForm}>
            <div className="row">
              <div className="col-12 col-md-12">
                <input
                  className="fields"
                  type="email"
                  placeholder={
                    pathname === '/book-event'
                      ? t('Please provide event details')
                      : t('Enter Email Address')
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    email === '' &&
                    t('Please enter your email')}
                </p>
              </div>
              {/* <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Select Cinema
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="fb">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Subject
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <input
                  className="fields"
                  type="text"
                  placeholder="Showtime and Ticket"
                />
              </div> */}
              <div className="col-12">
                <textarea
                  className="fields"
                  rows="8"
                  placeholder={t('type-suggestions')}
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    suggestions === '' &&
                    t('Please enter suggestions')}
                </p>
              </div>
              <div className="col-12 col-sm-5 mx-auto">
                <button className="btn-main mx-auto" type="submit">
                  {t('Submit')}
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </div>
    </>
  );
}

export default withNamespaces()(Feedback);
