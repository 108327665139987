import React, { useEffect, useState, useRef, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
import cinemaBg from '@assets/pictures/cinema-banner.png';
import cinema from '@assets/pictures/cinema.png';
import whiteArrowDown from '@assets/pictures/svgs/white-arrow-down.svg';
import map from '@assets/pictures/svgs/map.svg';
import Slider from 'react-slick';
import AuditoriumCard from './partials/AuditoriumCard.jsx';
import AncilliaryHeroSection from './partials/AncilliaryHeroSection.jsx';
import { fetchCinemas } from '../store/movies/movieActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
import Spinner from './partials/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import Nav from 'react-bootstrap/Nav';
// import AdvertiseImg1 from '@assets/pictures/AdvertiseImg_1.jpg';
import AdvertiseImg1 from '@assets/pictures/On Screen Advertise.jpg';
// import AdvertiseImg2 from '@assets/pictures/Main.jpg';
import AdvertiseImg2 from '@assets/pictures/LED Digital Display.JPG';
import AdvertiseImg3 from '@assets/pictures/Main_2.jpg';
import AdvertiseImg4 from '@assets/pictures/Product Branding.jpg';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import AdvertiseForm from './AdvertiseForm.jsx';
import GenericService from '@apiService/GenericService';
const CinemaListing = ({ t }) => {
  const dispatch = useDispatch();
  //   const cinemas = useSelector((state) => state.cinemas);
  //   const { cinemasLoading, allCinemas } = cinemas;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const history = useHistory();

  const [cms_pages_data, setcms_pages_data] = useState([]);
  const MspagesDataFunc = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await GenericService.GetMsPage(3);
      const { data } = response;
      if (data?.status) {
        setcms_pages_data(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(MspagesDataFunc());
  }, []);

  let adv_data = cms_pages_data?.map((x) => {
    return x.page_content;
  });
  console.log(adv_data, 'adv_data');
  // const [cinemaDetails, setCinemaDetails] = useState(false);
  // if (cinemaDetails === true) {
  //   history.push("/cinema-details");
  // }
  // useEffect(() => {
  //   if (!cinemas?.allCinemas) {
  //     dispatch(fetchAllCinemas());
  //   }
  //   window.scrollTo(0, 0);
  // }, []);
  //   useEffect(() => {
  //     dispatch(fetchAllCinemas());
  //   }, [dispatch]);
  useEffect(() => window.scrollTo(0, 0), []);
  const heroSectionObj = {
    arr: [1],
    backgroundImg: AdvertiseImg1,
    // title: t('cinema_listing.maintitle'),
    // msg: t('cinema_listing.maindesc'),
  };
  const [key, setKey] = useState('home');

  return (
    <div>
      <div className="container-fluid align_new">
        {/* <BreadCrumb firstHalf={`${t('Home')} >`} secondHalf={t('Location')} /> */}
        <CenteredTitle firstHalf={t('Advertise With Us')} secondHalf={''} />
      </div>
      <div className="px-0">
        {/* Hero Section */}
        {/* <Slider {...heroSlider} className="">
          {[1, 2].map((banner, index) => (
            <section className="cinema-hero-section" key={index}>
              <div className="hero-img-section">
                <img src={cinemaBg} alt="Movie Banner" className="hero-img-1" />
              </div>
              <div className="hero-text justify-content-center">
                <div className="container-fluid container-xl">
                  <div className="row">
                    <div className="col-10 col-sm-8 col-lg-6 mx-auto text-center">
                      <p className="title">Cinemas</p>
                      <p className="msg">
                        We have the biggest cinema loyalty scheme, we - offering
                        guests the chance to see all the films they want, as
                        often as they like.
                      </p>
                      <div className="">
                        <img src={whiteArrowDown} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </Slider> */}
        {/* <AncilliaryHeroSection sectionObj={heroSectionObj} /> */}

        {/* <img src={AdvertiseImg1} className="img-fluid awu_img" /> */}

        <section className="container-fluid container-xl mt-3 location_wrapper">
          <div className="">
            {/* <div
              className="advertise_data_wrapper"
              dangerouslySetInnerHTML={{ __html: adv_data.toString() }}
            /> */}
            <p className="awu_sub_text">
              Puts your business in front of our highly engaged audience, giving you
              the opportunity to gain visibility and drive leads. We offer a range
              of advertising solutions, so you get exactly the exposure you need to
              reach your target audience. Our team of experts will work with you to
              develop an effective advertising campaign tailored to your specific
              goals. With our extensive reach and powerful targeting capabilities,
              you can be sure that your message will be seen by the right people
            </p>
            <Row>
              <div
                className={`${
                  isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                }`}
              >
                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`d-flex justify-content-center align-items-center ${
                    isMobile ? 'mb-5' : 'mb-5'
                  }`}
                >
                  <img src={AdvertiseImg1} className="img-fluid awu_img" />
                </Col>
                
                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={` ${isMobile ? 'mb-2' : 'mb-5'}`}
                >
                    <div className={` ${isMobile ? ' ' : 'mt-5'}`} >
                  <h4 className="font-weight-bold">1. On-Screen Advertising</h4>
                  <p className="awu_sub_text">
                    Capture the attention of moviegoers with high-definition
                    on-screen advertisements before the movie begins. Our
                    crystal-clear screens and powerful audio systems provide an
                    immersive environment that enhances the impact of your message.
                    </p>
                   </div>
                  </Col>
                   

              </div>

              <Col sm={6} lg={6} xl={6} className={`${isMobile ? 'mb-2' : 'mb-5'}`}>
              <div className={` ${isMobile ? ' ' : 'mt-5'}`} >
                <h4 className="font-weight-bold">
                  2. Cinema LEDs and Digital Displays
                </h4>
                <p className="awu_sub_text">
                  Maximize your brand exposure with our eye-catching LED displays
                  and digital screens located throughout our cinema lobbies. These
                  dynamic advertising platforms ensure that your message is seen by
                  a high volume of patrons as they arrive, socialize, and wait in
                  the cinema areas.
                  </p>
                  </div>
              </Col>

              <Col
                sm={6}
                lg={6}
                xl={6}
                className={`d-flex justify-content-center align-items-center ${
                  isMobile ? 'mb-5' : 'mb-5'
                }`}
              >
                <img src={AdvertiseImg2} className="img-fluid awu_img" />
              </Col>

              <div
                className={`${
                  isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                }`}
              >
                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`d-flex justify-content-center align-items-center ${
                    isMobile ? 'mb-5' : 'mb-5'
                  }`}
                >
                  <img src={AdvertiseImg3} className="img-fluid awu_img" />
                </Col>
                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                >
                  <div className={` ${isMobile ? ' ' : 'mt-5'}`} >
                    <h4 className="font-weight-bold">3. In-Lobby Promotions</h4>
                  <p className="awu_sub_text">
                    Engage with moviegoers directly through creative in-lobby
                    promotions. Set up a booth to showcase your products, distribute
                    samples, or offer exclusive deals to Star Cinemas' patrons. This
                    interactive approach allows you to create memorable experiences
                    that can boost brand loyalty and drive sales.
                    </p>
                    </div>
                </Col>
              </div>
              <Col sm={6} lg={6} xl={6} className={`${isMobile ? 'mb-2' : 'mb-5'}`}>
              <div className={` ${isMobile ? ' ' : 'mt-5'}`} >
                <h4 className="font-weight-bold">
                  4. Product Placement and Co-Branding
                </h4>
                <p className="awu_sub_text">
                  Integrate your products or services into the moviegoing experience
                  through strategic product placement and co-branding opportunities.
                  From featuring your products in our concession stands to
                  partnering on special events and screenings, we offer various
                  avenues for collaboration that align with your brand and
                  objectives.
                  </p>
                  </div>
              </Col>
              <Col
                sm={6}
                lg={6}
                xl={6}
                className="mb-5 d-flex justify-content-center align-items-center"
              >
                <img src={AdvertiseImg4} className="img-fluid awu_img" />
              </Col>

              <Col sm={12} lg={12} xl={12}>
                <p className="awu_sub_text">
                  Take your advertising to the next level with Star Cinemas. To
                  learn more about our advertising opportunities or to discuss a
                  custom campaign, please contact our advertising team at
                  info@starcinemas.ae or complete the inquiry form below:
                </p>
              </Col>
            </Row>
            <AdvertiseForm />
          </div>
        </section>
      </div>
    </div>
  );
};

export default withNamespaces()(CinemaListing);
