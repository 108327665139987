import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import useIsTablet from '@src/customHooks/useIsTablet';
import InlineScroll from '@components/partials/InlineScroll';
// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import AlGhurair from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_Al Ghurair.png';
import National from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_National.png';
import KhalifaCity from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_Khalifa.png';
import GrandSafeer from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_Safeer.png';
import AlInn from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_AlAin.png';
import StarBawadi from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_Bawadi.png';
import StarCentury from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_Century.png';
import StarDana from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_Dana.png';
import GulfCinema from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_RAKGulf.png';
import UAQ from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_UAQ.png';
import GrandMall from '@assets/pictures/WhatsApp community_QR codes/WhatsApp Community_Ajman.png';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
const OpeningHours = ({ t }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const cinemas = useSelector((state) => state.cinemas);
  const { cinemasLoading, allCinemas } = cinemas;

  // console.log(allCinemas, 'allCinemas');
  useEffect(() => {
    dispatch(fetchAllCinemas());
  }, [dispatch]);
  const [show, setShow] = useState('');

  const handleshowhide = (event) => {
    const getCinemaelectedValue = event.target.value;
    console.log(getCinemaelectedValue, 'ffffffffff');
    setShow(getCinemaelectedValue);
  };
  const isTablet = useIsTablet();
  //   const handelQrImage = () => {
  //     if (show == '1') {
  //       return AlGhurair;
  //     } else if (show == '2') {
  //       return National;
  //     }
  //     return '';
  //   };
  return (
    <>
      <div
        className="container-fluid opening_hours custom_backgroung_img"
        style={{ height: '100vh' }}
      >
        <div className="d-flex justify-content-end align-items-center custom_select_wrapper">
          <select
            className={'custom_select_tickets'}
            onChange={(e) => handleshowhide(e)}
          >
            <option value="" disabled selected hidden>
              Click To Connect
            </option>
            {allCinemas?.map((x) => {
              return <option value={x?.cinema_id}>{x?.cinema_name}</option>;
            })}
            {/* <option value="2">NATIONAL CINEMA</option>
            <option value="3">STAR CINEMAS KHALIFA CITY</option>
            <option value="4">GRAND SAFEER CINEMA L.L.C</option>
            <option value="5">AL AIN MALL CINEMA</option>
            <option value="6">STAR BAWADI CINEMAS</option>
            <option value="7">STAR CINEMA GRAND MALL</option>
            <option value="8">STAR CINEMAS CENTURY</option>
            <option value="9">STAR CINEMAS DANA</option>
            <option value="10">GULF CINEMA</option>
            <option value="11">STAR CINEMAS LLC UAQ</option> */}
          </select>
        </div>
        <section
          className={` ${
            show == ''
              ? 'row justify-content-end justify-content-end invisible'
              : show !== '1'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={AlGhurair} className="img-fluid" />
          </article>
        </section>

        {/* {<!---Labim Mal--->} */}

        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '2'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={National} className="img-fluid" />
          </article>
        </section>

        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '3'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={KhalifaCity} className="img-fluid" />
          </article>
        </section>

        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '4'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={GrandSafeer} className="img-fluid" />
          </article>
        </section>

        {/* {<!---AlInn--->} */}
        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '5'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={AlInn} className="img-fluid" />
          </article>
        </section>

        {/* {<!---Star Bawadi--->} */}
        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '6'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={StarBawadi} className="img-fluid" />
          </article>
        </section>

        {/* {<!---Star Cinema GrandMall Ajman--->} */}
        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '7'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={GrandMall} className="img-fluid" />
          </article>
        </section>

        {/* {<!---Star Century--->} */}
        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '8'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={StarCentury} className="img-fluid" />
          </article>
        </section>

        {/* {<!---Star Dana--->} */}
        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '9'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={StarDana} className="img-fluid" />
          </article>
        </section>

        {/* {<!---Gulf Cinema--->} */}
        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '10'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={GulfCinema} className="img-fluid" />
          </article>
        </section>

        {/* {<!---UAQ--->} */}
        <section
          className={` ${
            show == ''
              ? 'd-none'
              : show !== '11'
              ? 'd-none'
              : 'row justify-content-end positioning_qr'
          }`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-2">
            <img src={UAQ} className="img-fluid" />
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(OpeningHours);
